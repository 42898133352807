import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./ThemeSettings.module.css";
import CloseX from "../../UIElements/CloseX/CloseX";
import mergeClasses from "classnames";

const key = "save-theme-in-localstorage";

const ThemeSettings = () => {
  const navigate = useNavigate();

  //Close modal, navigate to previous page
  const closeModalHandler = () => {
    navigate("/app/call-center/");
  };

  // dark mode functionality started here
  const [theme, setTheme] = useState(localStorage.getItem(key));

  const changeTheme = (status) => {

    const body = document.body;
    const toggle = document.querySelector(".toggle-inner");

    if (status === true) {
      body.classList.add('dark-theme');
      localStorage.setItem(key, 'dark');
      setTheme('dark');
    } else {
      body.classList.remove("dark-theme");
      localStorage.setItem(key, 'default');
      setTheme('default');
    }

  };

  return (
    <div className={classes.main}>
      <div className={classes.header}>
        <div className={classes.title}>Theme</div>
        <CloseX onClick={closeModalHandler} />
      </div>
      <div className={classes["menu_seperator"]}></div>
      <div className={classes.content}>
        {/* dark mode toggle is here */}
        <div className={classes.themebox}>
          <div
            id='dark'
            className={classes.toggle}
            onClick={() =>
              theme === 'dark' ? changeTheme(false) : changeTheme(true)
            }
          >
            <div className={
              mergeClasses(
                classes.toggleInner,
                theme === 'dark' && classes.toggleActive
              )}/>
          </div>
          <h4>Dark Theme</h4>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;