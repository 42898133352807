import React, { Suspense } from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation
} from "react-router-dom";
import Notification from "./shared/components/UIElements/Notification/Notification";
import IncomingCallBar from "./callCenter/components/Twilio/IncomingCallBar/IncomingCallBar";
import OutgiongCallBar from "./callCenter/components/Twilio/OutgoingCallBar/OutgoingCallBar";
import MessageNotification from "./callCenter/components/Twilio/MessageNotification/MessageNotification";
import ContactModal, {
  Messages
} from "./shared/components/Modal/ContactModal/ContactModal";
import NotesBox from "./shared/components/NotesBox/NotesBox";
import MessagesBox from "./shared/components/MessagesBox/MessagesBox";

import SettingsModal from "./shared/components/Modal/SettingsModal/SettingsModal";
import AccountSettings from "./shared/components/Modal/SettingsModal/AccountSettings";
import PhoneSettings from "./shared/components/Modal/SettingsModal/PhoneSettings";
import ThemeSettings from "./shared/components/Modal/SettingsModal/ThemeSettings";

import NewContactModal from "./shared/components/Modal/NewContactModal";
import { uiActions } from "./store/ui";
import { twilioActions } from "./store/twilio";
import { messageActions } from "./store/message";
import { voiceMessageActions } from "./store/voiceMessage";
import { io } from "socket.io-client";
import { fetchContactData } from "./store/contact-actions";
import classes from "./App.module.css";
import { logout } from "./store/user-actions";
import { userActions } from "./store/user";
import ChooseNumberModal from "./shared/components/Modal/ChooseNumberModal";
import NotificationModal from "./shared/components/Modal/NotificationModal/NotificationModal";
import ImportCSV from "./shared/components/CSVImport/CSVImport";
import {
  fetchVoiceMessages,
  fetchCalls,
  connectTwilioDevice
} from "./store/twilio-actions";
import { callActions } from "./store/call";
import { fetchEvents } from "./store/event-actions";
import "./styles/DarkMode.css";
import "./styles/AllModes.css";
import { toggleDeviceOnOffIncomingCalls } from "./store/twilio-actions";
import Calendar from "./calendar/pages/Calendar";
import CallCenter from "./callCenter/pages/CallCenter";
import Pipeline from "./pipeline/pages/Pipeline";
import PipelineBox from "./pipeline/components/PipelineBox";
import { fetchMessagesByUserId } from "./store/twilio-actions";
import {
  fetchPipelinesByUserId,
  fetchStagesByUserId,
  fetchDealsByUserId
} from "./store/pipeline-actions";
import { fetchTasks } from "./store/task-actions";
import DealModal from "./shared/components/Modal/DealModal/DealModal";
import CommunicationsSideDrawer from "./shared/components/CommunicationSideDrawer/ComminicationSideDrawer";
import CommunicationSideDrawerMessages from "./shared/components/CommunicationSideDrawer/CommunicationSideDrawerMessages";
import AddPipeline from "./pipeline/components/AddPipeline";
import EditPipeline from "./pipeline/components/EditPipeline";
const KEY = "save-theme-in-localstorage";

function App() {
  const dispatch = useDispatch();

  const Auth = React.lazy(() => import("./user/pages/Auth"));
  // const CallCenter = React.lazy(() => import("./callCenter/pages/CallCenter"));
  // const Calendar = React.lazy(() => import("./calendar/pages/Calendar"));

  const token = useSelector((state) => state.userReducer.token);

  //persist login on refresh if token has not expired
  let logoutTimer;

  const tokenExpirationDate = useSelector(
    (state) => state.userReducer.tokenExpirationDate
  );

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      dispatch(
        userActions.login({
          userId: storedData.userId,
          userName: storedData.userName,
          userEmail: storedData.userEmail,
          userSettings: storedData.userSettings,
          token: storedData.token,
          expirationDate: storedData.expiration
        })
      );
      dispatch(fetchContactData());
      dispatch(fetchVoiceMessages());
      dispatch(fetchCalls());
      dispatch(fetchEvents());
      dispatch(fetchMessagesByUserId());
      dispatch(fetchPipelinesByUserId());
      dispatch(fetchStagesByUserId());
      dispatch(fetchDealsByUserId());
      dispatch(fetchTasks());
    }
  }, []);

  //logout after token's 12 hour expiration
  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        new Date(tokenExpirationDate).getTime() - new Date().getTime();

      logoutTimer = setTimeout(() => {
        dispatch(logout());
      }, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, tokenExpirationDate]);

  //handle dark mode functionality
  useEffect(() => {
    const body = document.body;
    if (localStorage.getItem(KEY) == "dark") {
      body.classList.add("dark-theme");
    } else {
      body.classList.remove("dark-theme");
    }
  }, [localStorage.getItem(KEY)]);

  //activate device and set user online, do not set if no phone number (new user)
  const userHasPhoneNumber = useSelector(
    (state) => state.userReducer.user.userSettings.phone?.number
  );
  const storedData = JSON.parse(localStorage.getItem("userData"));
  const deviceReadyForRegistration = useSelector(
    (state) => state.twilioReducer.deviceReadyForRegistration
  );

  useEffect(() => {
    if (storedData?.isOnline && userHasPhoneNumber) {
      dispatch(connectTwilioDevice());
    }
  }, [userHasPhoneNumber, storedData?.isOnline]);

  useEffect(() => {
    dispatch(userActions.setIsOnline(true));
    dispatch(toggleDeviceOnOffIncomingCalls(false));
  }, [deviceReadyForRegistration]);

  //Receive call status updates from backend
  let socket;

  useEffect(() => {
    socket = io(process.env.REACT_APP_BACKEND_URL);
    socket.on("status", (updateStatus) => {
      if (updateStatus === "completed") {
        dispatch(twilioActions.setCallStatus(null));
        return;
      }
      dispatch(twilioActions.setCallStatus(updateStatus));
    });

    socket.on("human-detected", (humanDetected) => {
      dispatch(
        twilioActions.setMachineStatus({ humanDetected: humanDetected })
      );
    });

    socket.on("machine-detected", (machineDetected) => {
      dispatch(
        twilioActions.setMachineStatus({ machineDetected: machineDetected })
      );
    });

    socket.on("inbound-message", (inboundMessage) => {
      dispatch(messageActions.addMessage(inboundMessage.message));
      dispatch(messageActions.addRecentMessage(inboundMessage.message));
    });

    socket.on("voice-message", (voiceMessage) => {
      dispatch(voiceMessageActions.addVoiceMessage(voiceMessage.voiceMessage));
      dispatch(
        voiceMessageActions.addRecentVoiceMessage(voiceMessage.voiceMessage)
      );
    });

    socket.on("call", (call) => {
      dispatch(callActions.addCall(call.call));
    });
  }, []);

  //Spin on load to avoid app crash
  const AppSpinner = (props) => {
    return (
      <div className={classes["spinner-container"]}>
        <div className={`${classes.loader} ${classes["medium"]}`}></div>
      </div>
    );
  };

  let routes;

  if (token) {
    routes = (
      <Routes>
        <Route path="/app">
          <Route path="call-center" exact element={<CallCenter />}>
            <Route path="settings" exact element={<SettingsModal />}>
              <Route path="account" exact element={<AccountSettings />} />
              <Route path="phone" exact element={<PhoneSettings />} />
              <Route path="theme" exact element={<ThemeSettings />} />
            </Route>
            <Route
              path="communications"
              exact
              element={<CommunicationsSideDrawer />}
            >
              <Route
                path="messages"
                exact
                element={<CommunicationSideDrawerMessages />}
              />
              <Route path="calls" exact element={<div>calls</div>} />
              <Route path="voicemails" exact element={<div>voicemail</div>} />
            </Route>
            <Route path="contacts">
              <Route path="new" exact element={<NewContactModal />} />
              <Route path="import" exact element={<ImportCSV />} />
              <Route path=":contactId" exact element={<ContactModal />}>
                <Route path="notes" exact element={<NotesBox />} />
                <Route path="messages" exact element={<MessagesBox />} />
              </Route>
            </Route>
          </Route>
          <Route path="calendar" exact element={<Calendar />} />
          <Route path="pipeline" exact element={<Pipeline />}>
            <Route path="add" exact element={<AddPipeline />} />
            <Route path=":pipelineId/edit"  exact element={<EditPipeline />} />
            <Route path=":pipelineId" exact element={<PipelineBox />}>
              <Route path="deal">
                <Route path=":dealId" exact element={<DealModal />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/app/call-center" replace />} />
      </Routes>
    );
  } else {
    routes = (
      <main>
        <Routes>
          <Route path="auth" exact element={<Auth />}></Route>
          <Route path="*" element={<Navigate to="/auth" replace />} />
        </Routes>
      </main>
    );
  }

  return (
    <>
      <Notification />
      {token && <IncomingCallBar />}
      {token && <OutgiongCallBar />}
      {token && <MessageNotification />}
      {token && <ChooseNumberModal />}
      <Suspense fallback={<AppSpinner />}>{routes}</Suspense>
    </>
  );
}

export default App;
